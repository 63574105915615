<template>
  <div>
  <CCard>
   
  <CCardBody>
    <!-- <div class="clearfix">
      <button type="button" class="btn btn-secondary float-xl-right" @click="navToNewSymptoms">New </button>
    </div> -->

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination>
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <!-- <CDropdownItem :to="{name: 'Equipment', params: {equipmentId: item.equipmentId, mode: 'view'}}">View </CDropdownItem> -->
             <CDropdownItem :to="{name: 'Checklists', params: {equipmentId: item.equipmentId, mode: 'view'}}">Checklists </CDropdownItem>
             <!-- <CDropdownItem :to="{name: 'Models', params: {modelId: item.modelId, mode: 'view'}}">Models </CDropdownItem> -->
                 <!-- <CDropdownItem :to="{name: 'Sub Groups', params: {groupId: item.groupId, mode: 'view'}}">Sub Groups</CDropdownItem>
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteFaq(item.groupId)"
                    ></ConfirmationModal>-->

                </CDropdown> 

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "equipmentName",label: "Model Name", _classes: 'font-weight-bold',  _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Equipments",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/equipments/"+this.$route.params.subGroupId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   });
    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
    // deleteFaq(groupId) {
    //   console.log("delete called with", this.groupId);
    //  fetch(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/web/secure/delete/group/" +
    //      groupId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getDepartments();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //        window.location.reload();
    //     });
    // },
    // navToNewSymptoms(){
    //    this.$router.push({ name: "Group", params: { mode: "new" } });
    // }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>